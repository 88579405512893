<template>
  <div class="main-content q-pa-md">
    <Mural :breadcrumbs="breadcrumbs" route="links" :query="{}">
      <DefaultBtn titleButton="Salvar" icon="edit" @click="saveFavorite" />
    </Mural>
    <main>
      <div class="row col-12">
        <label class="title-group col-12">Nome do Site</label>
        <q-input
          class="col-11"
          outlined
          dense
          label="Digite um nome para o site.."
          color="default-pink"
          bg-color="grey-3"
          v-model="formFavorite.name"
        />
      </div>

      <div class="row col-12">
        <label class="title-group col-12">Url do Site</label>
        <q-input
          class="col-11"
          outlined
          dense
          label="Digite um nome para o site.."
          color="default-pink"
          bg-color="grey-3"
          v-model="formFavorite.url"
        />
      </div>

      <div class="row col-12">
        <label class="title-group col-12">Grupos</label>
        <div class="row col-12">
          <!-- :disable="isMaster && company_id == null ? true : false" -->
          <q-select
            v-model="formFavorite.groups"
            :options="listGroups"
            bg-color="grey-3"
            color="default-pink"
            class="col-11"
            style="height: 5px"
            multiple
            outlined
            dense
            emit-value
            map-options
            use-input
            input-debounce="0"
            @filter="filterFnGroup"
            :rules="[(val) => !!val || 'O campo categoria é obrigatório.']"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">
                  Não encontrado
                </q-item-section>
              </q-item>
            </template>
            <template
              v-slot:option="{ itemProps, opt, selected, toggleOption }"
            >
              <q-item v-bind="itemProps">
                <q-item-section>
                  <q-item-label v-html="opt.label" />
                </q-item-section>
                <q-item-section side>
                  <q-toggle
                    :model-value="selected"
                    @update:model-value="toggleOption(opt)"
                  />
                </q-item-section>
              </q-item>
            </template>
            <template v-slot:selected>
              <label>{{ formFavorite.groups != null ? formFavorite.groups.length : 0 }} grupos </label>
            </template>
          </q-select>
        </div>
      </div>
    </main>
    <q-inner-loading
      :showing="loading"
      label="Por favor aguarde..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    >
      <div class="collumn justify-center q-my-md">
        <div style="height: auto; width: 50px; margin: auto">
          <LoadingIcon />
        </div>
        <label>Por favor aguarde...</label>
      </div>
    </q-inner-loading>
  </div>
</template>

<script>
//----COMPONENTS
import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";

//----SERVICES
import GroupServices from "@/services/GroupServices";
import FavoriteServices from "@/services/FavoriteServices";

//----QUASAR
import { useQuasar } from "quasar";

//----VUE
import { ref, reactive, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import {useStore} from 'vuex'

export default {
  name: "NewFavotire",
  components: {
    Mural,
    DefaultBtn,
    LoadingIcon,
  },
  setup() {
    //----SERVICES
    const _favoriteServices = new FavoriteServices();
    const GroupService = new GroupServices();

    //----CONSTANTS
    const $q = new useQuasar();
    const breadcrumbs = ref(["Novo Favorito"]);
    const route = new useRoute();
    const router = new useRouter();
    const _favoriteId = route.query.favoriteId;
    const store = useStore()
    //----VARIABLES 
    let loading = ref(false);
    let listGroups = ref([]);
    let optionListGroups = ref([]);
    let formFavorite = reactive({
      name: "",
      url: "",
      groups:[]
    });

    onMounted(() => {
      _getFavorite();
      _listAllGroups();
    });

    async function _listAllGroups() {
      GroupService.listGroups()
        .then((response) => {
          response.data.map((item) => {
            listGroups.value.push({
              label: item.group_name,
              value: item.id,
              company_id: item.company_id,
            });
          });
          optionListGroups.value = listGroups.value;
        })

        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    async function _getFavorite() {
      if (_favoriteId == null) return null;
      loading.value = true;
      var response = await _favoriteServices.get(_favoriteId);

      formFavorite.name = response.data.name;
      formFavorite.url = response.data.url;
       formFavorite.groups = response.data.groups;
      loading.value = false;
    }

    function filterFnGroup(val, update) {
      if (val === "") {
        update(() => {
          listGroups.value = optionListGroups.value;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        listGroups.value = optionListGroups.value.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    async function saveFavorite() {
      if (formFavorite.url.indexOf("http") < 0) {
        _alertOf("Link Inválido, por favor inclua o http/https no link");
        return;
      }
      loading.value = true;

      const formData = new FormData();
      formData.append("name", formFavorite.name);
      formData.append("url", formFavorite.url);
      formData.append("companyId", store.state.userdata.company_id);

      formFavorite.groups.forEach(group => {
        formData.append("groups", group);  
      });
      

      if (_favoriteId == null) {
        await _favoriteServices
          .add(formData)
          .then(() => {
            router.push({ name: "links" });
            _alertOf("Link salvo com sucesso", "green-9");
            loading.value = false;
          })
          .catch((error) => {
            _alertOf(error.response.data.toString());
            loading.value = false;
            return;
          });
      } else {
        await _favoriteServices
          .update(_favoriteId, formData)
          .then(() => {
            router.push({ name: "links" });
            _alertOf("Link salvo com sucesso", "green-9");
            loading.value = false;
          })
          .catch((error) => {
            _alertOf(error.response.data.toString());
            loading.value = false;
            return;
          });
      }

      loading.value = false;
    }

    function _alertOf(message, color = "red-9") {
      $q.notify({
        message: message,
        color: color,
        position: "top",
      });
    }

    return {
      //----VARIABLES
      formFavorite,
      breadcrumbs,
      loading,
      listGroups,

      //----FUNCTIONS
      saveFavorite,
      filterFnGroup,
    };
  },
};
</script>

<style lang="scss" scoped>
.container-options {
  overflow-y: auto;
  height: 86%;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 30px 0px;
}

.title-group {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  opacity: 0.45;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0px;
}

.title-options {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #000000;
}

.title-status {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  color: #9c9c9c;
}

.title-content {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 17px;
  display: flex;
  padding-left: 20px;
  align-items: center;
  text-transform: capitalize;
  color: #000000;
}

.content-options {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 20px;
  flex: none;
  order: 1;
  height: 50px;
  flex-grow: 0;
  margin: 10px 0px;
}
.content-options:hover {
  background-color: #f3f1f1;
  border: 1px solid #fe5268;
  transition-delay: 0.2ms;
}
.chat-drop {
  background: #ffffff;
  border-bottom: 1px solid #e5e5e5;
  box-sizing: border-box;
}
.btn-drop {
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  width: 100%;
  height: 100%;
}
//.btn-drop:hover{
//  background-color: #FFF8EE;
//  border: 1px solid #FE5268;
//  transition-delay: 0.2ms;
//}
</style>
