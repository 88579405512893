import http from "../http-common";

export default class GroupServices {
  async listGroups() {
    let res = http.get("v1/group");
    return res;
  }
  async listBranches() {
    let res = http.get("v1/group/list-branch");
    return res;
  }

  async getGroup(groupId) {
    let res = http.get(`v1/group/${groupId}`);
    return res;
  }

  async disabled(groupId) {
    return http.delete(`v1/group/${groupId}`);
  }

  async addGroup(data) {
    return await http.post(`v1/group`, data);
  }

  async updateGroup(groupId, data) {
    return await http.put(`v1/group/${groupId}`, data);
  }

  async addBranch(branchName) {
    return await http.post(`v1/group/add-branch?nameBranch=${branchName}`);
  }

  async editBranch(branchId, branchName) {
    return await http.put(
      `v1/group/edit-branch/${branchId}?nameBranch=${branchName}`
    );
  }

  async disableBranch(branchId) {
    return http.delete(`v1/group/disable-branch/${branchId}`);
  }
}
