import http from '../http-common'

export default class FavoriteServices {

    async getAll() {
        return http.get("v1/favorite");
    }

    async get(favoriteId) {
        return http.get(`v1/favorite/${favoriteId}`);
    }
    
    async disabled(favoriteId) {
        return http.delete(`v1/favorite/${favoriteId}`);
    }

    async add(data){
        return await http.post(`v1/favorite`, data);
    }

    async update(favoriteId, data){
        return await http.put(`v1/favorite/${favoriteId}`, data);
    }
}